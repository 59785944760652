import App from '@/App.vue';
import { i18n } from "@/i18n";
import quasarUserOptions from '@/quasar-user-options';
import router from '@/router';
import store from "@/store";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from 'vue';

const useTestEndpoint = false;
export const VUE_APP_TEST_ENDPOINT = useTestEndpoint ? "https://bvrpay.test.bankvostok.com" : null;

createApp(App)
    .use(router)
    .use(i18n)
    .use(store)
    .use(Quasar, quasarUserOptions)
    .mount('#app');
